var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user !== null
    ? _c(
        "form",
        {
          staticClass: "flex flex-col",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.update($event)
            }
          }
        },
        [
          _c(
            "nav",
            {
              staticClass:
                "flex flex-row justify-between px-3 mt-3 pb-2 border-b border-blue-500 items-end"
            },
            [
              _c("v-back", {
                attrs: {
                  close: true,
                  fallback: _vm.$router.resolve({ name: "account-detail" })
                }
              }),
              _c("p", {
                staticClass: "font-semibold w-full ml-12 text-center",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("the_account_detail.label_user_profile")
                  )
                }
              }),
              _c("button", {
                staticClass: "btn btn-blue rounded-full py-1 px-3 focus:shadow",
                class: { "btn-loading": _vm.loading },
                attrs: { type: "submit", disabled: _vm.loading },
                domProps: { textContent: _vm._s(_vm.$t("general.button_save")) }
              })
            ],
            1
          ),
          _c("ul", { staticClass: "list-disc list-inside" }, [
            _vm.error
              ? _c("li", {
                  staticClass: "form-error",
                  domProps: { textContent: _vm._s(_vm.error) }
                })
              : _vm._e(),
            _vm.errors.has("current_password")
              ? _c("li", {
                  staticClass: "form-error",
                  domProps: {
                    textContent: _vm._s(_vm.errors.first("current_password"))
                  }
                })
              : _vm._e(),
            _vm.errors.has("password")
              ? _c("li", {
                  staticClass: "form-error",
                  domProps: {
                    textContent: _vm._s(_vm.errors.first("password"))
                  }
                })
              : _vm._e(),
            _vm.errors.has("password_confirmation")
              ? _c("li", {
                  staticClass: "form-error",
                  domProps: {
                    textContent: _vm._s(
                      _vm.errors.first("password_confirmation")
                    )
                  }
                })
              : _vm._e()
          ]),
          _c("div", { staticClass: "form-fieldset" }, [
            _c("p", {
              staticClass: "form-label",
              domProps: {
                textContent: _vm._s(
                  _vm.$t("the_account_password_edit.label_current_password")
                )
              }
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.updatedUser.currentPassword,
                  expression: "updatedUser.currentPassword"
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              staticClass: "form-input",
              attrs: {
                type: "password",
                name: "firstname",
                "data-vv-validate-on": "submit",
                placeholder: _vm.$t("general.placeholder_required")
              },
              domProps: { value: _vm.updatedUser.currentPassword },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.updatedUser,
                    "currentPassword",
                    $event.target.value
                  )
                }
              }
            })
          ]),
          _c("div", { staticClass: "form-fieldset" }, [
            _c("p", {
              staticClass: "form-label",
              domProps: {
                textContent: _vm._s(
                  _vm.$t("the_account_password_edit.label_new_password")
                )
              }
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.updatedUser.password,
                  expression: "updatedUser.password"
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              ref: "password",
              staticClass: "form-input w-auto",
              attrs: {
                type: "password",
                name: "password",
                "data-vv-validate-on": "submit",
                placeholder: _vm.$t("general.placeholder_required")
              },
              domProps: { value: _vm.updatedUser.password },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.updatedUser, "password", $event.target.value)
                }
              }
            })
          ]),
          _c("div", { staticClass: "form-fieldset" }, [
            _c("p", {
              staticClass: "form-label",
              domProps: {
                textContent: _vm._s(
                  _vm.$t(
                    "the_account_password_edit.label_new_password_confirmation"
                  )
                )
              }
            }),
            _c("input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|confirmed:password",
                  expression: "'required|confirmed:password'"
                }
              ],
              staticClass: "form-input",
              attrs: {
                type: "password",
                name: "password_confirmation",
                "data-vv-validate-on": "submit",
                placeholder: _vm.$t("general.placeholder_required")
              }
            })
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }